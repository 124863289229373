import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/mdx-page.jsx";
import Testimonial from '../../components/Testimonial';
import { roadmapService } from '../../../data/SiteConfig';
import { printGbpWithUsd } from '../../utils/formatting';
import ContactForm from '../../components/ContactForm';
import SimpleFaqList from '../../components/SimpleFaqList';
export const faqs = [{
  question: 'What happens after I apply?',
  answer: 'I will review your application and reply by email, typically within one business day, with any immediate follow-up questions, and to schedule a preliminary conversation to ensure the service is the right fit for you. In this call, I’ll let you know when I’m available to start working with you.'
}, {
  question: 'When will we have our workshop meeting?',
  answer: 'After payment is received, I’ll provide you with a calendar link to book our workshop session. Note that I only host meetings during my working hours of Monday to Friday, 9am to 5pm Irish Time (UTC+0 in winter, UTC+1 in summer).'
}, {
  question: 'What are your payment terms?',
  answer: 'Payment is due in full before commencing our engagement. I accept payments by credit card (all countries) or bank transfers in the US and UK.'
}, {
  question: 'I still have more questions that I’d like to ask before applying.',
  answer: 'No worries. Please go ahead and complete the form below and let me know about what further questions you have.'
}];
export const _frontmatter = {};
const layoutProps = {
  faqs,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2>{`Who’s this for?`}</h2>
    <p>{`You’re about to start building a new application using AWS serverless technologies. You have limited experience with using it in production and want to avoid making costly mistakes at an early stage.`}</p>
    <h2>{`What’s included?`}</h2>
    <p>{`I can help accelerate your development team by giving you a tailored plan of action to take you through to production and beyond.`}</p>
    <p>{`Here’s what you’ll get:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Questionnaire`}</strong>{`: A detailed assessment of the business, organisational, functional and technical goals & requirements of your new product`}</li>
      <li parentName="ul"><strong parentName="li">{`Roadmapping Workshop`}</strong>{` (2–3hrs video call): Requirements gathering session with you/your lead developers where we go deep on key use cases and integrations`}</li>
      <li parentName="ul">{`Written `}<strong parentName="li">{`Application Roadmap Report`}</strong>{` that includes:`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`High-level Architecture`}</strong>{`: what AWS services should you use and how will they fit together`}</li>
          <li parentName="ul"><strong parentName="li">{`AWS Account Management`}</strong>{`: how should you structure your AWS accounts for your application`}</li>
          <li parentName="ul"><strong parentName="li">{`Development Environment Setup`}</strong>{`: how should you structure your codebase and what frameworks and libraries should you use`}</li>
          <li parentName="ul"><strong parentName="li">{`DevOps Recommendations`}</strong>{`: suggested processes and tools deployment, CI/CD, monitoring, etc.`}</li>
          <li parentName="ul"><strong parentName="li">{`Upskilling Recommendations`}</strong>{`: links to recommended learning resources to help plug any significant skills gaps identified in your team`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Q & A Session`}</strong>{` (1hr video call): Post-report follow-up call discussing the report and answering your questions`}</li>
    </ul>
    <p>
  Price:{' '}
  <span className="font-bold">{printGbpWithUsd(roadmapService.priceGBP)}</span>.
    </p>
    <p className="text-center my-8">
  <a className="btn bg-primary-500 hover:bg-primary-600 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline font-sans" href="#applyForm">
    Apply now
  </a>
    </p>
    <h2>What clients are saying</h2>
    <section className="mb-8">
  <Testimonial authorName="Patrick" authorTitle="Co-Founder & CTO at LandKeep" authorPhotoUrl="/img/client-logos/patrick-o.jpeg" mdxType="Testimonial">
    <p>
      Working with Paul was one of the best decisions we’ve made for our app! We
      knew going with a serverless architecture would be the best approach for
      our app, but due to a lack of experience, we wanted help from someone who
      was a subject matter expert in this field.
    </p>
    <p>
      I now have a lot better sense of how to build out our application’s
      infrastructure and{' '}
      <strong className="font-extrabold">
        I have the peace of mind that we are doing things in a secure, scalable
        way and won't waste hours of development work
      </strong>{' '}
      implementing a sub optimal solution.
    </p>
    <p>
      Paul did a great job of explaining key serverless concepts, recommending
      the appropriate architecture for us, complete with all the AWS services to
      use, and how to tie them together, and he dove into our business-critical
      workflows with us to make sure we knew how to implement each one within a
      serverless context.
    </p>
    <p>
      I also liked how <em>human</em> the interactions felt, as opposed to
      something very transactional or business-like. Yes, Paul is running a
      business but at no point in our interaction did I feel like I was just
      another “client” to him or an afterthought. He took his time to answer all
      my questions, tailor his recommendations to our specific use cases, and
      showed a genuine interest in our project and organization. And <strong className="font-extrabold">I feel like
      I have “someone in my corner” that I can go to for help with future
      engagements</strong>, should I need it. Highly recommend working with Paul for your
      next serverless application!
    </p>
  </Testimonial>
    </section>
    <section>
  <h2>
    Still have questions?
  </h2>
  <SimpleFaqList faqs={faqs} mdxType="SimpleFaqList" />
    </section>
    <section id="applyForm" className="mt-10 lg:mt-2 mb-10 mx-auto">
  <h2>
    Apply now
  </h2>
  <p>
    To book a roadmap engagement or to learn more about how it could help you,
    fill out the form I’ll get back to you ASAP with details on how to proceed.
  </p>
  <ContactForm formName="Roadmap" messageLabel="Tell me a little about the product you’re building" buttonText="Submit Roadmap Request" hideSubject mdxType="ContactForm" />
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      